.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    text-align: center;
  }


.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}

/* Base chip style */
.role-chip {
  display: inline-block;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    color: #8a5c32;
    text-align: center;
    background-color: #fde8b1;
    margin: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
}


.admin-chip {
  background-color: #a2f2ff;
  color: #0b7889; 
}

/* Super Admin-specific chip */
.super-admin-chip {
  background-color: #f57da2; 
  color: #900045; 
}

/* Member-specific chip */
.member-chip {
  background-color: #bfa9f7; 
  color: #3908b0; 
}

/* Chip for 'Demo' status */
.status-chip {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.status-chip-demo {
  background-color: #ffdd99;
  color: #663300;
}

.status-chip-paid {
  background-color: #99ccff;
  color: #003366;
}

.btn-custom {
  float: right;  
  padding: 8px 12px;
  border-radius: 5px; 
  background-color: #28a745; 
  color: white; 
  text-decoration: none; 
  width: auto; 
  max-width: 120px; 
  transition: background-color 0.3s; 
}

.btn-custom:hover {
  background-color: #218838; 
}


/* General Button Styles */
.btn {
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
  padding: 8px 12px;
  font-size: 0.875rem; 
  border-radius: 5px; 
  text-align: center;
  cursor: pointer; 
  width: 70px; 
  overflow: hidden; 
  white-space: nowrap; 
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
}

.btn-primary {
  background-color: #007bff; 
  color: white;
  border: none;
}

/* Spinner for Delete Button */
.spinner-border {
  width: 1rem; 
  height: 1rem;
  border-width: 2px; 
}

/* Extra Spacing Between Buttons */
.mr-2 {
  margin-right: 10px; 
}


/* dialog Css  */

/* Overlay Style */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top */
}

/* Dialog Box Style */
.dialog-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  text-align: center;
}

/* Dialog Buttons Container */
.dialog-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px;
}

/* Confirm Delete Button */
.btn-delete-confirm {
  background-color: #dc3545; 
  color: white;
  border: none;
  padding: 8px 12px;
  width: 100px;
  border-radius: 5px;
  transition: background-color 0.3s;
  gap: 15px;
}

.btn-delete-confirm:hover {
  background-color: #c82333; 
}

/* Cancel Delete Button */
.btn-delete-cancel {
  background-color: #6c757d; 
  color: white;
  border: none;
  padding: 8px 12px;
  width: 100px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-delete-cancel:hover {
  background-color: #5a6268; 
}

.pagination-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.page-indicator {
  margin: 0 10px;
  font-weight: bold;
}


