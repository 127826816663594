html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

#app,
.app-container,
.container {
  width: 100%;
  height: 100%;
}

a {
  cursor: pointer;
}

.app-container {
  min-height: 320px;
}

.card-header {
  /*background: -linear-gradient(315deg, #1e30f3 0%, #e21e80 100%);
	background:-webkit-linear-gradient(315deg, #1e30f3 0%, #e21e80 100%);*/
  background: transparent;
  color: #868686;
  text-align: center;
  border-bottom: none;
  margin-bottom: 10px;
}
.admin-nav {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #e8e9ea;
  border-bottom: 1px solid #ccc;
}

/* ANIMATION */
@-moz-keyframes cycle {
  0% {
    top: 0px;
  }
  4% {
    top: 0px;
  }
  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  20% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  21% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  92% {
    top: -325px;
    opacity: 0;
    z-index: 0;
  }
  96% {
    top: -325px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
@-moz-keyframes cycletwo {
  0% {
    top: -325px;
    opacity: 0;
  }
  16% {
    top: -325px;
    opacity: 0;
  }
  20% {
    top: 0px;
    opacity: 1;
  }
  24% {
    top: 0px;
    opacity: 1;
  }
  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  40% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  41% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@-moz-keyframes cyclethree {
  0% {
    top: -325px;
    opacity: 0;
  }
  36% {
    top: -325px;
    opacity: 0;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  44% {
    top: 0px;
    opacity: 1;
  }
  56% {
    top: 0px;
    opacity: 1;
  }
  60% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  61% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@-moz-keyframes cyclefour {
  0% {
    top: -325px;
    opacity: 0;
  }
  56% {
    top: -325px;
    opacity: 0;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  64% {
    top: 0px;
    opacity: 1;
  }
  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  80% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  81% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@-moz-keyframes cyclefive {
  0% {
    top: -325px;
    opacity: 0;
  }
  76% {
    top: -325px;
    opacity: 0;
  }
  80% {
    top: 0px;
    opacity: 1;
  }
  84% {
    top: 0px;
    opacity: 1;
  }
  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  100% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
}

@keyframes cycle {
  0% {
    top: 0px;
  }
  4% {
    top: 0px;
  }
  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  20% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  21% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  92% {
    top: -325px;
    opacity: 0;
    z-index: 0;
  }
  96% {
    top: -325px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
@keyframes cycletwo {
  0% {
    top: -325px;
    opacity: 0;
  }
  16% {
    top: -325px;
    opacity: 0;
  }
  20% {
    top: 0px;
    opacity: 1;
  }
  24% {
    top: 0px;
    opacity: 1;
  }
  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  40% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  41% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@keyframes cyclethree {
  0% {
    top: -325px;
    opacity: 0;
  }
  36% {
    top: -325px;
    opacity: 0;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  44% {
    top: 0px;
    opacity: 1;
  }
  56% {
    top: 0px;
    opacity: 1;
  }
  60% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  61% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@keyframes cyclefour {
  0% {
    top: -325px;
    opacity: 0;
  }
  56% {
    top: -325px;
    opacity: 0;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  64% {
    top: 0px;
    opacity: 1;
  }
  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  80% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  81% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@keyframes cyclefive {
  0% {
    top: -325px;
    opacity: 0;
  }
  76% {
    top: -325px;
    opacity: 0;
  }
  80% {
    top: 0px;
    opacity: 1;
  }
  84% {
    top: 0px;
    opacity: 1;
  }
  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  100% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
}

@-webkit-keyframes cycle {
  0% {
    top: 0px;
  }
  4% {
    top: 0px;
  }
  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  20% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  21% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  50% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  92% {
    top: -325px;
    opacity: 0;
    z-index: 0;
  }
  96% {
    top: -325px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
@-webkit-keyframes cycletwo {
  0% {
    top: -325px;
    opacity: 0;
  }
  16% {
    top: -325px;
    opacity: 0;
  }
  20% {
    top: 0px;
    opacity: 1;
  }
  24% {
    top: 0px;
    opacity: 1;
  }
  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  40% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  41% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@-webkit-keyframes cyclethree {
  0% {
    top: -325px;
    opacity: 0;
  }
  36% {
    top: -325px;
    opacity: 0;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  44% {
    top: 0px;
    opacity: 1;
  }
  56% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  60% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  61% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@-webkit-keyframes cyclefour {
  0% {
    top: -325px;
    opacity: 0;
  }
  56% {
    top: -325px;
    opacity: 0;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  64% {
    top: 0px;
    opacity: 1;
  }
  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  80% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  81% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}
@-webkit-keyframes cyclefive {
  0% {
    top: -325px;
    opacity: 0;
  }
  76% {
    top: -325px;
    opacity: 0;
  }
  80% {
    top: 0px;
    opacity: 1;
  }
  84% {
    top: 0px;
    opacity: 1;
  }
  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  100% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
}

/* ANIMATION BAR */
@-moz-keyframes fullexpand {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    width: 0%;
    opacity: 0;
  }
  4%,
  24%,
  44%,
  64%,
  84% {
    width: 0%;
    opacity: 0.3;
  }
  16%,
  36%,
  56%,
  76%,
  96% {
    width: 100%;
    opacity: 0.7;
  }
  17%,
  37%,
  57%,
  77%,
  97% {
    width: 100%;
    opacity: 0.3;
  }
  18%,
  38%,
  58%,
  78%,
  98% {
    width: 100%;
    opacity: 0;
  }
}
@-webkit-keyframes fullexpand {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    width: 0%;
    opacity: 0;
  }
  4%,
  24%,
  44%,
  64%,
  84% {
    width: 0%;
    opacity: 0.3;
  }
  16%,
  36%,
  56%,
  76%,
  96% {
    width: 100%;
    opacity: 0.7;
  }
  17%,
  37%,
  57%,
  77%,
  97% {
    width: 100%;
    opacity: 0.3;
  }
  18%,
  38%,
  58%,
  78%,
  98% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes fullexpand {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    width: 0%;
    opacity: 0;
  }
  4%,
  24%,
  44%,
  64%,
  84% {
    width: 0%;
    opacity: 0.3;
  }
  16%,
  36%,
  56%,
  76%,
  96% {
    width: 100%;
    opacity: 0.7;
  }
  17%,
  37%,
  57%,
  77%,
  97% {
    width: 100%;
    opacity: 0.3;
  }
  18%,
  38%,
  58%,
  78%,
  98% {
    width: 100%;
    opacity: 0;
  }
}

/* CONTENT SLIDER */
#content-slider {
  width: 522px;
  height: 456px;
  margin: 10px auto 0;
  margin-top: 3em;
}
/* SLIDER */
#slider {
  border: 10px solid #f8f8f8;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  margin: 10px auto 0;
  overflow: hidden;
  position: relative;
  /* background-color: red; */
}

/* @media only screen and (max-width: 500px) {
  #slider {
    height: 80%;
  }
} */

#slider ul {
  margin: 0;
  padding: 0;
  position: relative;
  /* background-color: blue; */
}
#slider li {
  width: 100%;
  height: 490px;
  top: -100%;
  list-style: none;
  background-size: contain;
  background-repeat: no-repeat;
  /* overflow: hidden; */
  position: absolute;
  right: 0;
  left: 0;
  /* background-color: yellow; */
}

#slider li.firstanimation {
  -moz-animation: cycle 10s linear infinite;
  -webkit-animation: cycle 10s linear infinite;
  animation: cycle 10s linear infinite;
  background-image: url("https://digisign24.s3.ap-south-1.amazonaws.com/users-media/web-assets/1.png");
  background-size: contain;
  background-position: center;
}
#slider li.secondanimation {
  -moz-animation: cycletwo 10s linear infinite;
  -webkit-animation: cycletwo 10s linear infinite;
  animation: cycletwo 10s linear infinite;
  background-image: url("https://digisign24.s3.ap-south-1.amazonaws.com/users-media/web-assets/2.png");
  background-size: contain;
  background-position: center;
}
#slider li.thirdanimation {
  -moz-animation: cyclethree 10s linear infinite;
  -webkit-animation: cyclethree 10s linear infinite;
  animation: cyclethree 10s linear infinite;
  background-image: url("https://digisign24.s3.ap-south-1.amazonaws.com/users-media/web-assets/3.png");
  background-size: contain;
  background-position: center;
}
#slider li.fourthanimation {
  -moz-animation: cyclefour 10s linear infinite;
  -webkit-animation: cyclefour 10s linear infinite;
  animation: cyclefour 10s linear infinite;
  background-image: url("https://digisign24.s3.ap-south-1.amazonaws.com/users-media/web-assets/4.png");
  background-size: contain;
  background-position: center;
}
#slider li.fifthanimation {
  -moz-animation: cyclefive 10s linear infinite;
  -webkit-animation: cyclefive 10s linear infinite;
  animation: cyclefive 10s linear infinite;
  background-image: url("https://digisign24.s3.ap-south-1.amazonaws.com/users-media/web-assets/5.png");
  background-size: contain;
  background-position: center;
}

#slider .tooltip {
  background: rgba(0, 0, 0, 0.7);
  width: 300px;
  height: 60px;
  position: relative;
  bottom: 75px;
  left: -320px;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
#slider .tooltip h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  line-height: 60px;
  padding: 0 0 0 20px;
}

/* PROGRESS BAR */
.progress-bar {
  position: relative;
  top: -5px;
  width: 100%;
  height: 5px;
  background: #000;
  -moz-animation: fullexpand 10s ease-out infinite;
  -webkit-animation: fullexpand 10s ease-out infinite;
  animation: fullexpand 10s ease-out infinite;
}

/* Member details */

.mem-btn {
  border: none;
  padding: 9px;
  color: #76d812 !important;
  /* font-weight: 600; */
  font-size: 18px;
  outline: none;
  cursor: pointer;
}

.ad-role {
  border: none;
  background: none;
  color: #2f14ab;
  font-size: 19px;
  font-weight: 400;
  cursor: pointer;
  outline: none !important;
}

@media (orientation: portrait) {
  #logo {
    display: none;
  }
  .col-sm-auto {
    width: 100%;
  }
  #content-slider {
    width: 100%;
  }
  .bg-secondary-subtle{
    zoom:1.2;
  }
}
/*End PORTRAIT*/

.alert-danger {
  color: red;
}

.alert-success {
  color: green;
}
.user-details li {
  list-style: none;
}
.user-detail-section li label {
  font-weight: bold;
}
.user-detail-section li span {
  margin-left: 20px;
}

#bill_summary div label {
  width: 100%;
  margin-right: 10px;
}

.Form_blur_background {
  backdrop-filter: blur(12px);
}

.box_shadow {
  -webkit-box-shadow: 0px 0px 59px -18px rgba(74, 74, 74, 1);
  -moz-box-shadow: 0px 0px 59px -18px rgba(74, 74, 74, 1);
  box-shadow: 0px 0px 59px -18px rgba(74, 74, 74, 1);
}

.mybtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}

.statusButton {
  padding: 4px 7px !important;
    border-radius: 6px;
    letter-spacing: 1px;
}

.user-detail-section{ 
    margin-top: 20px;
    background: #fff;
    padding: 10px 20px;
    border-radius: 10px;
}
.user-details{ 
background:#e9e9e9;
}
.user-detail-section li label{ 

width:260px;
}

.input-text{
      padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal {
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.25);
}

.modal-container {
  max-height: 90vh;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
 
}
.invoiceModelContainer{
  max-height: 90vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
 
}

.modal-container-header {
  padding: 16px 32px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-container-title {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  font-weight: 700;
  font-size: 1.125;
  
}

.modal-container-body {
  padding: 10px 20px;
  overflow-y: auto;
}

.icon-button {
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.15s ease;
 
}
.icon-button:hover{
  background-color: #dfdad7;
}

.modal-popup .modal-container-footer {
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  gap: 12px;
  position: relative;
}

.modal-popup  .button {
  padding: 12px 20px;
  border-radius: 8px;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: 0.15s ease;
  }

  .modal-popup  .button:hover{
    background-color: #dfdad7;
  }

 .modal-popup  .button.is-primary {
    background-color: #0d57d9;
    color: #fff;
}

.border-only-btn{
    padding: 12px 20px;
  border-radius: 8px;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: 0.15s ease;
  border:1px solid #ccc;

}

 .border-only-btn:hover{
    border-color: #0d57d9;
  }


  .checkbox-common {
  height: 30px;
  width: 30px;
  }

.badge-warning {
    color: #995601;
    background-color: #ffebae;
}

.table-striped tbody tr:nth-of-type(odd)
{
  background-color: transparent!important;
}

.row input {
      border: 1px solid #ccc;
    border-radius: 5px;
}